.main-row {
	background-color: #f0f2f5;
	height: 100vh;
	font-family: Montserrat;
}

.thank-you {
	font-size: 32px;
	font-weight: 600;
	text-align: center;
	color: #444444;
	margin-bottom: 20px;
}

.thank-you-decription {
	font-size: 20px;
	text-align: center;
	color: #444444;
}

.payment-btn {
	height: 40px;
	width: 300px;
	background-color: #f0f2f5;
	margin-top: 20px;
}

.ucardia-support {
	font-size: 13px;
	color: #444444;
}

.ucardia-copyright {
	font-size: 11px;
	color: #00000073;
}
