@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);


.checkout-main-div {
	height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.demographic-heading {
	font-size: 16px;
	font-weight: 500;
	margin-left: 20px;
	margin-top: 15px;
}

.contact-heading {
	font-size: 16px;
	font-weight: 500;
	margin-left: 20px;
	margin-top: 15px;
}

.enrollment-form {
	text-align: center;
	margin-top: 20px;
}

.enrollment-form > p {
	font-size: 20px;
	font-weight: bold;
}

.enroll-btn-style {
	font-weight: 500;
	width: 100px;
}

.main-row {
	background-color: #f0f2f5;
	height: 100vh;
	font-family: Montserrat;
}

.thank-you {
	font-size: 32px;
	font-weight: 600;
	text-align: center;
	color: #444444;
	margin-bottom: 20px;
}

.thank-you-decription {
	font-size: 20px;
	text-align: center;
	color: #444444;
}

.payment-btn {
	height: 40px;
	width: 300px;
	background-color: #f0f2f5;
	margin-top: 20px;
}

.ucardia-support {
	font-size: 13px;
	color: #444444;
}

.ucardia-copyright {
	font-size: 11px;
	color: #00000073;
}

