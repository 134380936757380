.enrollment-form {
	text-align: center;
	margin-top: 20px;
}

.enrollment-form > p {
	font-size: 20px;
	font-weight: bold;
}

.enroll-btn-style {
	font-weight: 500;
	width: 100px;
}
